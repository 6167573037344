html,
body {
  margin: 0;
  padding: 0;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.ant-upload.ant-upload-select {
  width: 100%;
}

.ant-table-thead th {
  text-align: center !important;
}

:where(.css-dev-only-do-not-override-k83k30).ant-table-wrapper .ant-table.ant-table-small {
  font-size: 12px;
}

.ant-table-tbody tr:nth-child(even) {
  background-color: #f6f6f6;
}

table td, table th {
  border-inline-end-color: #e3e3e3 !important;
  border-bottom-color: #e3e3e3 !important;

  border-inline-start-color: #e3e3e3 !important;
  border-top-color: #e3e3e3 !important;
}

.ant-table-body {
  overflow-y: auto !important;
}

:where(.css-dev-only-do-not-override-k83k30).ant-layout {
  background: #f8f8f8 !important;
}

.ant-table-container {
  border: 1px solid #d5d5d5 !important;
}