html, body {
  margin: 0;
  padding: 0;
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.-bottom-4 {
  bottom: -1rem;
}

.bottom-10 {
  bottom: 2.5rem;
}

.left-0 {
  left: 0;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.ml-12 {
  margin-left: 3rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.flex {
  display: -ms-flexbox;
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-12 {
  height: 3rem;
}

.h-24 {
  height: 6rem;
}

.h-max {
  height: max-content;
}

.h-screen {
  height: 100vh;
}

.max-h-\[780px\] {
  max-height: 780px;
}

.w-12 {
  width: 3rem;
}

.w-64 {
  width: 16rem;
}

.w-96 {
  width: 24rem;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.max-w-\[1000px\] {
  max-width: 1000px;
}

.max-w-\[700px\] {
  max-width: 700px;
}

.flex-1 {
  -ms-flex: 1;
  flex: 1;
}

.resize {
  resize: both;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-\[100px_160px\] {
  grid-template-columns: 100px 160px;
}

.grid-cols-\[100px_1fr\] {
  grid-template-columns: 100px 1fr;
}

.grid-cols-\[120px_1fr_120px_1fr\] {
  grid-template-columns: 120px 1fr 120px 1fr;
}

.grid-cols-\[70px_200px\] {
  grid-template-columns: 70px 200px;
}

.grid-rows-\[32px_32px\] {
  grid-template-rows: 32px 32px;
}

.grid-rows-\[32px_32px_32px\] {
  grid-template-rows: 32px 32px 32px;
}

.grid-rows-\[32px_32px_32px_32px_32px_32px_32px_32px_32px\] {
  grid-template-rows: 32px 32px 32px 32px 32px 32px 32px 32px 32px;
}

.grid-rows-\[32px_32px_32px_32px_32px_32px_32px_32px_32px_32px\] {
  grid-template-rows: 32px 32px 32px 32px 32px 32px 32px 32px 32px 32px;
}

.grid-rows-\[min-content\] {
  grid-template-rows: min-content;
}

.grid-rows-\[min-content_min-content_min-content\] {
  grid-template-rows: min-content min-content min-content;
}

.flex-row {
  -ms-flex-direction: row;
  flex-direction: row;
}

.flex-col {
  -ms-flex-direction: column;
  flex-direction: column;
}

.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.items-center {
  -ms-flex-align: center;
  align-items: center;
}

.justify-start {
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.justify-center {
  -ms-flex-pack: center;
  justify-content: center;
}

.justify-between {
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.justify-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.gap-0 {
  gap: 0;
}

.gap-0\.5 {
  gap: .125rem;
}

.gap-1 {
  gap: .25rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-y-1 {
  row-gap: .25rem;
}

.gap-y-6 {
  row-gap: 1.5rem;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded {
  border-radius: .25rem;
}

.border {
  border-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-slate-300 {
  --tw-border-opacity: 1;
  border-color: rgba(203, 213, 225, var(--tw-border-opacity));
}

.bg-slate-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 245, 249, var(--tw-bg-opacity));
}

.bg-slate-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(203, 213, 225, var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.p-2 {
  padding: .5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.text-center {
  text-align: center;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.text-blue-300 {
  --tw-text-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.ant-upload.ant-upload-select {
  width: 100%;
}

.ant-table-thead th {
  text-align: center !important;
}

:where(.css-dev-only-do-not-override-k83k30).ant-table-wrapper .ant-table.ant-table-small {
  font-size: 12px;
}

.ant-table-tbody tr:nth-child(2n) {
  background-color: #f6f6f6;
}

table td, table th {
  border-bottom-color: #e3e3e3 !important;
  border-top-color: #e3e3e3 !important;
}

table td:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)), table th:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
  border-left-color: #e3e3e3;
  border-right-color: #e3e3e3;
}

table td:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)), table th:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-left-color: #e3e3e3;
  border-right-color: #e3e3e3;
}

.ant-table-body {
  overflow-y: auto !important;
}

:where(.css-dev-only-do-not-override-k83k30).ant-layout {
  background: #f8f8f8 !important;
}

.ant-table-container {
  border: 1px solid #d5d5d5 !important;
}

@media (min-width: 640px) {
  .sm\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .sm\:ml-4 {
    margin-left: 1rem;
  }

  .sm\:flex {
    display: -ms-flexbox;
    display: flex;
  }

  .sm\:grid {
    display: grid;
  }

  .sm\:w-40 {
    width: 10rem;
  }

  .sm\:w-64 {
    width: 16rem;
  }

  .sm\:w-72 {
    width: 18rem;
  }

  .sm\:w-80 {
    width: 20rem;
  }

  .sm\:max-w-3xl {
    max-width: 48rem;
  }

  .sm\:max-w-sm {
    max-width: 24rem;
  }

  .sm\:flex-none {
    -ms-flex: none;
    flex: none;
  }

  .sm\:grid-flow-row {
    grid-auto-flow: row;
  }

  .sm\:grid-cols-\[100px_1fr_100px_1fr\] {
    grid-template-columns: 100px 1fr 100px 1fr;
  }

  .sm\:grid-cols-\[100px_1fr_100px_1fr_100px_1fr_100px_1fr\] {
    grid-template-columns: 100px 1fr 100px 1fr 100px 1fr 100px 1fr;
  }

  .sm\:flex-row {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .sm\:flex-row-reverse {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  .sm\:justify-end {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

@media (min-width: 768px) {
  .md\:block {
    display: block;
  }

  .md\:inline-block {
    display: inline-block;
  }

  .md\:flex {
    display: -ms-flexbox;
    display: flex;
  }

  .md\:inline-flex {
    display: -ms-inline-flexbox;
    display: inline-flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:max-w-\[100px\] {
    max-width: 100px;
  }

  .md\:max-w-\[120px\] {
    max-width: 120px;
  }

  .md\:max-w-\[140px\] {
    max-width: 140px;
  }

  .md\:max-w-\[160px\] {
    max-width: 160px;
  }

  .md\:max-w-\[200px\] {
    max-width: 200px;
  }

  .md\:max-w-\[240px\] {
    max-width: 240px;
  }

  .md\:max-w-\[300px\] {
    max-width: 300px;
  }
}

:root {
  --react-pdf-annotation-layer: 1;
  --annotation-unfocused-field-background: url("data:image/svg+xml;charset=UTF-8,<svg width='1px' height='1px' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill:rgba(0, 54, 255, 0.13);'/></svg>");
  --input-focus-border-color: Highlight;
  --input-focus-outline: 1px solid Canvas;
  --input-unfocused-border-color: transparent;
  --input-disabled-border-color: transparent;
  --input-hover-border-color: black;
}

@media (forced-colors: active) {
  :root {
    --input-focus-border-color: CanvasText;
    --input-unfocused-border-color: ActiveText;
    --input-disabled-border-color: GrayText;
    --input-hover-border-color: Highlight;
  }

  .annotationLayer .textWidgetAnnotation input:required, .annotationLayer .textWidgetAnnotation textarea:required, .annotationLayer .choiceWidgetAnnotation select:required, .annotationLayer .buttonWidgetAnnotation.checkBox input:required, .annotationLayer .buttonWidgetAnnotation.radioButton input:required {
    outline: 1.5px solid selectedItem;
  }
}

.annotationLayer {
  pointer-events: none;
  transform-origin: 0 0;
  --scale-factor: 1;
  position: absolute;
  top: 0;
  left: 0;
}

.annotationLayer section {
  text-align: initial;
  pointer-events: auto;
  box-sizing: border-box;
  transform-origin: 0 0;
  position: absolute;
}

.annotationLayer .linkAnnotation > a, .annotationLayer .buttonWidgetAnnotation.pushButton > a {
  width: 100%;
  height: 100%;
  font-size: 1em;
  position: absolute;
  top: 0;
  left: 0;
}

.annotationLayer .buttonWidgetAnnotation.pushButton > canvas {
  width: 100%;
  height: 100%;
}

.annotationLayer .linkAnnotation > a:hover, .annotationLayer .buttonWidgetAnnotation.pushButton > a:hover {
  opacity: .2;
  background: #ff0;
  box-shadow: 0 2px 10px #ff0;
}

.annotationLayer .textAnnotation img {
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
}

.annotationLayer .textWidgetAnnotation input, .annotationLayer .textWidgetAnnotation textarea, .annotationLayer .choiceWidgetAnnotation select, .annotationLayer .buttonWidgetAnnotation.checkBox input, .annotationLayer .buttonWidgetAnnotation.radioButton input {
  background-image: var(--annotation-unfocused-field-background);
  border: 2px solid var(--input-unfocused-border-color);
  box-sizing: border-box;
  font: calc(9px * var(--scale-factor)) sans-serif;
  height: 100%;
  vertical-align: top;
  width: 100%;
  margin: 0;
}

.annotationLayer .textWidgetAnnotation input:required, .annotationLayer .textWidgetAnnotation textarea:required, .annotationLayer .choiceWidgetAnnotation select:required, .annotationLayer .buttonWidgetAnnotation.checkBox input:required, .annotationLayer .buttonWidgetAnnotation.radioButton input:required {
  outline: 1.5px solid red;
}

.annotationLayer .choiceWidgetAnnotation select option {
  padding: 0;
}

.annotationLayer .buttonWidgetAnnotation.radioButton input {
  border-radius: 50%;
}

.annotationLayer .textWidgetAnnotation textarea {
  resize: none;
}

.annotationLayer .textWidgetAnnotation input[disabled], .annotationLayer .textWidgetAnnotation textarea[disabled], .annotationLayer .choiceWidgetAnnotation select[disabled], .annotationLayer .buttonWidgetAnnotation.checkBox input[disabled], .annotationLayer .buttonWidgetAnnotation.radioButton input[disabled] {
  border: 2px solid var(--input-disabled-border-color);
  cursor: not-allowed;
  background: none;
}

.annotationLayer .textWidgetAnnotation input:hover, .annotationLayer .textWidgetAnnotation textarea:hover, .annotationLayer .choiceWidgetAnnotation select:hover, .annotationLayer .buttonWidgetAnnotation.checkBox input:hover, .annotationLayer .buttonWidgetAnnotation.radioButton input:hover {
  border: 2px solid var(--input-hover-border-color);
}

.annotationLayer .textWidgetAnnotation input:hover, .annotationLayer .textWidgetAnnotation textarea:hover, .annotationLayer .choiceWidgetAnnotation select:hover, .annotationLayer .buttonWidgetAnnotation.checkBox input:hover {
  border-radius: 2px;
}

.annotationLayer .textWidgetAnnotation input:focus, .annotationLayer .textWidgetAnnotation textarea:focus, .annotationLayer .choiceWidgetAnnotation select:focus {
  border: 2px solid var(--input-focus-border-color);
  outline: var(--input-focus-outline);
  background: none;
  border-radius: 2px;
}

.annotationLayer .buttonWidgetAnnotation.checkBox :focus, .annotationLayer .buttonWidgetAnnotation.radioButton :focus {
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

.annotationLayer .buttonWidgetAnnotation.checkBox :focus {
  border: 2px solid var(--input-focus-border-color);
  outline: var(--input-focus-outline);
  border-radius: 2px;
}

.annotationLayer .buttonWidgetAnnotation.radioButton :focus {
  border: 2px solid var(--input-focus-border-color);
  outline: var(--input-focus-outline);
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:before, .annotationLayer .buttonWidgetAnnotation.checkBox input:checked:after, .annotationLayer .buttonWidgetAnnotation.radioButton input:checked:before {
  background-color: CanvasText;
  content: "";
  display: block;
  position: absolute;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:before, .annotationLayer .buttonWidgetAnnotation.checkBox input:checked:after {
  height: 80%;
  width: 1px;
  left: 45%;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:before {
  transform: rotate(45deg);
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:after {
  transform: rotate(-45deg);
}

.annotationLayer .buttonWidgetAnnotation.radioButton input:checked:before {
  height: 50%;
  width: 50%;
  border-radius: 50%;
  top: 20%;
  left: 30%;
}

.annotationLayer .textWidgetAnnotation input.comb {
  padding-left: 2px;
  padding-right: 0;
  font-family: monospace;
}

.annotationLayer .textWidgetAnnotation input.comb:focus {
  width: 103%;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input, .annotationLayer .buttonWidgetAnnotation.radioButton input {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

.annotationLayer .popupTriggerArea {
  height: 100%;
  width: 100%;
}

.annotationLayer .popupWrapper {
  font-size: calc(9px * var(--scale-factor));
  width: 100%;
  min-width: calc(180px * var(--scale-factor));
  pointer-events: none;
  position: absolute;
}

.annotationLayer .popup {
  max-width: calc(180px * var(--scale-factor));
  box-shadow: 0 calc(2px * var(--scale-factor)) calc(5px * var(--scale-factor)) #888;
  border-radius: calc(2px * var(--scale-factor));
  padding: calc(6px * var(--scale-factor));
  margin-left: calc(5px * var(--scale-factor));
  cursor: pointer;
  font: message-box;
  white-space: normal;
  word-wrap: break-word;
  pointer-events: auto;
  background-color: #ff9;
  position: absolute;
}

.annotationLayer .popup > * {
  font-size: calc(9px * var(--scale-factor));
}

.annotationLayer .popup h1 {
  display: inline-block;
}

.annotationLayer .popupDate {
  margin-left: calc(5px * var(--scale-factor));
  display: inline-block;
}

.annotationLayer .popupContent {
  margin-top: calc(2px * var(--scale-factor));
  padding-top: calc(2px * var(--scale-factor));
  border-top: 1px solid #333;
}

.annotationLayer .richText > * {
  white-space: pre-wrap;
  font-size: calc(9px * var(--scale-factor));
}

.annotationLayer .highlightAnnotation, .annotationLayer .underlineAnnotation, .annotationLayer .squigglyAnnotation, .annotationLayer .strikeoutAnnotation, .annotationLayer .freeTextAnnotation, .annotationLayer .lineAnnotation svg line, .annotationLayer .squareAnnotation svg rect, .annotationLayer .circleAnnotation svg ellipse, .annotationLayer .polylineAnnotation svg polyline, .annotationLayer .polygonAnnotation svg polygon, .annotationLayer .caretAnnotation, .annotationLayer .inkAnnotation svg polyline, .annotationLayer .stampAnnotation, .annotationLayer .fileAttachmentAnnotation {
  cursor: pointer;
}

.annotationLayer section svg {
  width: 100%;
  height: 100%;
  position: absolute;
}

.annotationLayer .annotationTextContent {
  width: 100%;
  height: 100%;
  opacity: 0;
  color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  position: absolute;
}

.annotationLayer .annotationTextContent span {
  width: 100%;
  display: inline-block;
}

:root {
  --react-pdf-text-layer: 1;
}

.textLayer {
  text-align: initial;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  forced-color-adjust: none;
  line-height: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.textLayer span, .textLayer br {
  color: rgba(0, 0, 0, 0);
  white-space: pre;
  cursor: text;
  transform-origin: 0 0;
  position: absolute;
}

.textLayer span.markedContent {
  height: 0;
  top: 0;
}

.textLayer .highlight {
  background-color: #b400aa;
  border-radius: 4px;
  margin: -1px;
  padding: 1px;
}

.textLayer .highlight.appended {
  position: initial;
}

.textLayer .highlight.begin {
  border-radius: 4px 0 0 4px;
}

.textLayer .highlight.end {
  border-radius: 0 4px 4px 0;
}

.textLayer .highlight.middle {
  border-radius: 0;
}

.textLayer .highlight.selected {
  background-color: #006400;
}

.textLayer br::selection {
  background: none;
}

.textLayer .endOfContent {
  z-index: -1;
  cursor: default;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: block;
  position: absolute;
  top: 100%;
  bottom: 0;
  left: 0;
  right: 0;
}

.textLayer .endOfContent.active {
  top: 0;
}

/*# sourceMappingURL=index.17e4fe10.css.map */
